

export default {
  layout: 'blank',
  created() {
    this.$store.commit('GET_TOKEN')
    if (!this.$store.state.tokenInfo) {
      this.$router.push({
        path: 'login',
        query: {
          t: 'login'
        }
      })
    } else {
      this.$store.dispatch('GET_EID').then(() => {
        this.$router.replace({
          path: '/home'
        })
      }).catch(() => {
        this.$router.replace({
          path: '/company'
        })
      })
    }
  }
}
